// @ngInject
function AcpContactCsComponentCtrl($scope, nsUtil) {
  $scope.shouldShowTitle = function() {
    if (!nsUtil.isDefined($scope.showTitle)) {
      return true;
    }

    return $scope.showTitle === 'true';
  };
}

export default AcpContactCsComponentCtrl;
