import acpContactCsTemplate from './templates/acp-contact-cs.html';
// @ngInject
function acpContactCsDirective() {
  return {
    restrict: 'E',
    scope: {
      showTitle: '@'
    },
    controller: 'AcpContactCsComponentCtrl',
    template: acpContactCsTemplate
  };
}

export default acpContactCsDirective;
