import contactCsContent from './contact-cs-content.yml';
import acpContactCsCtrl from './acp-contact-cs-ctrl';
import acpContactCsDirective from './acp-contact-cs-directive';
import acpCore from 'core';
import ng from 'angular';
import './styles/${theme}/core.scss';

/**
 * @acpdoc component
 * @ngdoc module
 * @name acp.component.contact-cs
 *
 * @requires acp.core
 *
 * @description
 *
 * @example
 * ```html
 * <acp-contact-cs></acp-contact-cs>
 * ```
 *
 * ```js
 * angular.module('acp.component.contact-cs.example', [
 *   require('acp.component.contact-cs').name
 * ]);
 * ```
 */
var component = ng
  .module('acp.component.contact-cs', [acpCore.name])
  .directive('acpContactCs', acpContactCsDirective)
  .controller('AcpContactCsComponentCtrl', acpContactCsCtrl)
  .run(
    /*@ngInject*/ function(contentSectionCache) {
      contentSectionCache.put('components/contact-cs', contactCsContent);
    }
  );

export default component;
